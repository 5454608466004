@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* As the followings are default styles, they must be placed after base layer */

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #000000;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
  border-radius: 10px;
}
html {
  scrollbar-color: #ffffff #000000;
  scrollbar-width: auto;
  font-family: "Inter", sans-serif;
}

body {
  background-color: #111827;
}
:root {
  --toastify-toast-width: 400px; /* Change the width value as per your requirements */
}

.animated-text {

  font:"Oswald", sans-serif;
  letter-spacing: 0;
  padding: .25em 0 .325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(255,255,255,.5);

  /* Clip Background Image */

  background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
  -webkit-background-clip: text;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;
  animation: aitf 80s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  
}

/* Animate Background Image */

@-webkit-keyframes aitf {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

.attribute{
  position: absolute;
  bottom: 10px;
}



[type="checkbox"]:checked,
[type="checkbox"]:not(:checked){
display: none;
}

.checkbox:checked + label,
.checkbox:not(:checked) + label{
  position: relative;
  display: block;
  text-align: center;
  width: 60px;
  height: 16px;
  border-radius: 8px;
  padding: 0;
  margin: 10px auto;
  cursor: pointer;
  background-color:#9ca3af ;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before{
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #9ca3af;
  background-color: #6366f1;
  font-family: 'unicons';
  content: '\eb4f';
  z-index: 20;
  top: -10px;
  left: -10px;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  transition: all 0.5s ease;
}
.checkbox:checked + label:before {
  transform: translateX(44px) rotate(-270deg);
}
.card-3d-wrap {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 800px;
  margin-top: 60px;
}
.card-3d-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 600ms ease-out; 
}
.card-front, .card-back {
  -webkit-transform-style: preserve-3d;
}
.card-back {
  transform: rotateY(180deg);
}
.checkbox:checked ~ .card-3d-wrap .card-3d-wrapper {
  transform: rotateY(180deg);
}
